import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"

import { renderRichText } from "../../lib/renderRichText"

import "./info.scss"

const Info = ({ data }) => {
  const { placeholderImage } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "moving-dog.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container className="pt-5 pb-5 paper">
      <Row>
        <Col md={12}>
          <h2 className="font-weight-bold">{data.infoTitle}</h2>
          <div>{renderRichText(data.intoText.json)}</div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Img fluid={placeholderImage.childImageSharp.fluid} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={12}>
          <h2 className="font-weight-bold">{data.specialistTitle}</h2>
          <div>{renderRichText(data.specialistInfo.json)}</div>
        </Col>
      </Row>
    </Container>
  )
}
export default Info
