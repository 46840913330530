import React from "react"
import { Container } from "react-bootstrap"

import ManIcon from "../../assets/icons/postman.svg"
import TruckIcon from "../../assets/icons/moving-truck.svg"
import "./pricing.scss"
import { renderRichText } from "../../lib/renderRichText"

const Pricing = ({ allContentfulPriceCard, contentfulPricePage }) => {
  const getIcons = numberOfIcons => {
    let icons = []
    for (let i = 0; i < numberOfIcons; i++) {
      icons.push(ManIcon)
    }
    return icons
  }
  return (
    <div className="pricing pt-5 pb-5">
      <Container>
        <h2 className="font-weight-bold mb-4">{contentfulPricePage.title}</h2>
        <div className="d-flex flex-wrap price-cards-wrapper">
          {allContentfulPriceCard.edges.map(({ node }) => {
            return (
              <div className="price-card mb-4 mr-2" key={node.price}>
                <div className="d-flex flex-column align-items-center">
                  <div className="mb-4 d-flex">
                    {getIcons(node.numberOfHelpers).map(Icon => (
                      <Icon />
                    ))}
                  </div>
                  {node.withTruck && <TruckIcon className="mb-2" />}
                </div>

                <div>
                  <h4>
                    {node.title} {node.withTruckText}
                  </h4>
                  <h5>{node.price}</h5>
                  <p>{node.additionalPrice}</p>
                </div>
              </div>
            )
          })}
        </div>
        <div>{renderRichText(contentfulPricePage.info.json)}</div>
      </Container>
    </div>
  )
}

export default Pricing
