import React from "react"

import "./hero.scss"

const Hero = ({ data }) => {
  return (
    <div className="hero d-flex align-items-center justify-content-center">
      <div className="text">
        <h1 className="font-weight-bold display-3">{data.heroTitle}</h1>
        <h1 className="font-weight-bold display-4">{data.heroSubtitle}</h1>
        <h2 className="display-5">
          <b>
            <a
              className="primary-text font-weight-bold"
              href={`tel:${data.phoneNumber}`}
            >
              {data.phoneNumber}
            </a>
          </b>
        </h2>
      </div>
    </div>
  )
}

export default Hero
