import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Hero from "../components/homepage/hero"
import Info from "../components/homepage/info"

import { Container } from "react-bootstrap"
import Pricing from "../components/homepage/pricing"

import Services from "../components/homepage/services"

import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

const IndexPage = props => {
  const {
    contentfulHomepage,
    allContentfulPriceCard,
    contentfulPricePage,
    allContentfulService,
    contentfulGeneralInfo,
    contentfulServicesPage,
  } = props.data
  const desc = documentToPlainTextString(contentfulHomepage.intoText.json)
  return (
    <>
      <SEO
        desc={desc}
        title={contentfulHomepage.title}
        lang={contentfulHomepage.node_locale}
      />
      <Hero data={{ ...contentfulHomepage, ...contentfulGeneralInfo }} />
      <Container>
        <Info data={contentfulHomepage} />
      </Container>
      <Pricing
        allContentfulPriceCard={allContentfulPriceCard}
        contentfulPricePage={contentfulPricePage}
      />
      <Services
        title={contentfulServicesPage.title}
        allContentfulService={allContentfulService}
      />
    </>
  )
}

export const pageQuery = graphql`
  query homePageQuery($locale: String!) {
    contentfulServicesPage(
      pageName: { eq: "services" }
      node_locale: { eq: $locale }
    ) {
      node_locale
      title
    }

    contentfulGeneralInfo(name: { eq: "general" }) {
      email
      phoneNumber
    }

    contentfulHomepage(
      pageTitle: { eq: "home" }
      node_locale: { eq: $locale }
    ) {
      heroTitle
      title
      heroSubtitle
      infoTitle
      node_locale
      intoText {
        json
      }
      metaTags
      pageTitle
      pageUrl
      specialistTitle
      specialistInfo {
        json
      }
    }
    contentfulPricePage(
      pageName: { eq: "prices" }
      node_locale: { eq: $locale }
    ) {
      info {
        json
      }
      title
    }
    allContentfulPriceCard(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: order }
    ) {
      edges {
        node {
          price
          recomanded
          numberOfHelpers
          title
          withTruck
          withTruckText
          additionalPrice
        }
      }
    }
    allContentfulService(
      limit: 3
      filter: { node_locale: { eq: $locale } }
      sort: { fields: createdAt, order: ASC }
    ) {
      edges {
        node {
          serviceIcon
          serviceTitle
          serviceDescription {
            json
          }
        }
      }
    }
  }
`
export default IndexPage
