import React from "react"
import { Link } from "gatsby"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { Container, Row, Col } from "react-bootstrap"

import { icons } from "../../components/icons"
import "./services.scss"

const Services = ({ allContentfulService, title }) => {
  return (
    <div className="services pt-5 pb-5">
      <Container className="services-wrapper">
        <h2 className="font-weight-bold">{title}</h2>
        <Row>
          {allContentfulService.edges.map(({ node }) => {
            const serviceDescription = documentToPlainTextString(
              node.serviceDescription.json
            )
            return (
              <Col className="mb-4" key={node.serviceTitle} md={4}>
                <div className="paper service pr-4 pl-4 pt-2 pb-2">
                  <div className="mb-2">{icons[node.serviceIcon]}</div>

                  <h4>{node.serviceTitle}</h4>
                  <p>
                    {serviceDescription.slice(0, 120).concat(`...`)}
                    <Link to={`/services`}>Mehr</Link>
                  </p>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}
export default Services
